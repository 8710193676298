import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
// import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/HighlightOff";
import Typography from "@mui/material/Typography";

// import Container from "@mui/material/Container";

interface Props {
  result?: any;
}

export default function ShowResultContainer({ result }: Props) {
  const [isExpanded, setExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (result) {
      setExpanded(true);
    }
  }, [result]);

  const handleToggleExpand = () => {
    setExpanded(!isExpanded);
  };

  const handleCloseBox = () => {
    setIsVisible(false);
  };

  return (
    // <Container
    //   sx={{
    //     // display: "flex",
    //     position: "absolute",
    //     // height: "30px", // Adjust as needed
    //     // width: "30px", // Adjust as needed
    //     marginLeft: "px",
    //     top: "52px",
    //     // left: "5px",
    //   }}
    // >
    <Box
      className="search-result"
      sx={{
        height: isExpanded ? "unset" : "33px",
        display: isVisible ? "block" : "none",
        marginTop: "2px",
      }}
    >
      <div
        style={{
          fontSize: "11px",
          width: "100%",
        }}
      >
        <Grid container>
          <Grid item sm={8} md={8} lg={8}>
            {isExpanded ? (
              <SearchIcon
                sx={{
                  fontSize: "large",
                  borderRadius: "5px",
                }}
              />
            ) : (
              <Typography variant="caption">Search Result</Typography>
            )}
          </Grid>
          <Grid
            item
            sm={4}
            md={4}
            lg={4}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {isExpanded ? (
              <ExpandLessOutlinedIcon
                sx={{
                  fontSize: "large",
                  cursor: "pointer",
                  // border: "1px solid black",
                  borderRadius: "3px",
                }}
                onClick={handleToggleExpand}
              />
            ) : (
              <ExpandMoreOutlinedIcon
                sx={{
                  fontSize: "large",
                  cursor: "pointer",
                  // border: "1px solid black",
                  marginLeft: "4px",
                  borderRadius: "3px",
                }}
                onClick={handleToggleExpand}
              />
            )}
            <CloseIcon
              sx={{
                fontSize: "large",
                cursor: "pointer",
                // border: "1px solid black",
                marginLeft: "4px",
                borderRadius: "3px",
              }}
              onClick={handleCloseBox}
            />
          </Grid>
        </Grid>

        {isExpanded && (
          <div style={{ marginTop: "10px" }}>
            <Typography variant="subtitle1">Search Result</Typography>
            {result && (
              <ul>
                <li style={{ fontWeight: "bold" }}>
                  {result.address_components[0].long_name}
                </li>
                {result.formatted_address}
              </ul>
            )}
          </div>
        )}
      </div>
    </Box>
  );
}
