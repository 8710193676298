import * as React from "react";
// import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
// import Container from "@mui/material/Container";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

export default function FormLabel({ layers, callback }: any) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setChecked(event.target.checked);
    // console.log(event);
    if (typeof callback !== "undefined") {
      callback({
        id: event?.target?.id,
        checked: event?.target?.checked,
      });
    }
  };

  return (
    <div className="layercontainer">
      {/* <CssBaseline /> */}

      {/* <Container maxWidth="sm"> */}
      <Box
        className="layercontrol"
        sx={{
          bgcolor: "rgba(255, 255, 255, 0.5)",
          width: "100%",
          // bgcolor: "#EAEFF2",
          // height: "40px",
          // width: "140px",
          // // opacity: ".7",
          // top: "67px",
          // right: "35px",
          // position: "absolute",
          overflow: "hidden",
          padding: "3px",
          borderRadius: "5px",
          border: "1px solid #B8B8B8",
        }}
      >
        {/* <CheckboxLabels /> */}
        <FormGroup>
          {/* <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="2G Coverage"
            />
            <FormControlLabel control={<Checkbox defaultChecked />} label="4G-LTE" />
            <FormControlLabel control={<Checkbox defaultChecked />} label="5G-LTE" /> */}
          {typeof layers !== "undefined" &&
            layers.map((layer: any, idx: number) => (
              <FormControlLabel
                key={idx}
                control={
                  <Checkbox
                    checked={layer?.display ? layer?.display : false}
                    id={layer?.id}
                    onChange={handleChange}
                    sx={{
                      transform: "scale(0.6)",

                      // left: "10px",
                      // border: "2px solid black",
                    }}
                    // size="small"
                  />
                }
                label={
                  <>
                    {/* <div> */}
                    <Typography variant="caption" sx={{ fontSize: "10px" }}>
                      {layer?.label}
                    </Typography>
                    <div
                      style={{
                        backgroundColor: `${layer?.color}`,
                        width: "90px",
                        height: "7px",
                      }}
                    ></div>
                    {/* </div> */}
                  </>
                }
              />
            ))}
        </FormGroup>
      </Box>
      {/* </Container> */}
    </div>
  );
}

// function CheckboxLabels({ layers }: any) {
//   return (
//     <FormGroup sx={{ fontWeight: "bolder" }}>
//       {/* <FormControlLabel
//         control={<Checkbox defaultChecked />}
//         label="2G Coverage"
//       />
//       <FormControlLabel control={<Checkbox defaultChecked />} label="4G-LTE" />
//       <FormControlLabel control={<Checkbox defaultChecked />} label="5G-LTE" /> */}
//       {typeof layers !== "undefined" &&
//         layers.map((layer: any, idx: number) => (
//           <FormControlLabel
//             control={<Checkbox defaultChecked />}
//             label="4G-LTE"
//           />
//         ))}
//     </FormGroup>
//   );
// }

// export default SimpleContainer;
