import React, { useEffect, useCallback } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

import MapStyle from "./mapStyle.json";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 3.1569,
  lng: 101.7123,
};

// console.log(MapStyle);

function MyComponent({ callback, layers, mapcallback, location }: any) {
  const [marker, setMarker] = React.useState<any>(null);
  // const [layers, setLayers] = React.useState<any[]>([
  //   {
  //     id: "5g",
  //     url: "https://maxisnetwork.tuxgeo.dev/gwc/service/gmaps?SRS=EPSG:900913&layers=maxis:coverage_5g&zoom={z}&x={x}&y={y}&format=image/png8",
  //     display: true,
  //   },
  //   {
  //     id: "4g",
  //     url: "https://maxisnetwork.tuxgeo.dev/gwc/service/gmaps?SRS=EPSG:900913&layers=maxis:coverage_4g&zoom={z}&x={x}&y={y}&format=image/png8",
  //     display: true,
  //   },
  //   {
  //     id: "2g",
  //     url: "https://maxisnetwork.tuxgeo.dev/gwc/service/gmaps?SRS=EPSG:900913&layers=maxis:coverage_2g&zoom={z}&x={x}&y={y}&format=image/png8",
  //     display: true,
  //   },
  // ]);
  const [maplayers, setMaplayers] = React.useState<any[]>();
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GMAPKEY
      ? process.env.REACT_APP_GMAPKEY
      : "",
  });
  const [mapserver] = React.useState<string | null>(
    process.env.REACT_APP_MAPSERVER ? process.env.REACT_APP_MAPSERVER : ""
  );

  const onLoad = React.useCallback(
    function callback(map: google.maps.Map) {
      setMap(map);

      if (mapcallback) {
        const geocoder = new google.maps.Geocoder();

        mapcallback(map, geocoder);
      }
    },
    [mapcallback]
  );

  const baseurl = window.location.origin.endsWith("tuxgeo.com")
    ? ""
    : "https://maxisnetwork.tuxgeo.dev";

  const getCoverage = useCallback(
    (location: any) => {
      // const getCoverage = (location: any) => {
      // console.log(location);
      fetch(
        baseurl +
          "/site/maxis/coverage.cgi/" +
          location.lat +
          "," +
          location.lng
      )
        //status coverage

        .then((response) => response.json())
        .then((data) => {
          // if (data?.status && data.status === "OK") {
          //   window.alert("Within coverage");
          // } else {
          //   window.alert("Not within coverage");
          // }
          data["latlng"] =
            location.lat.toFixed(6) + "," + location.lng.toFixed(6);
          if (map) {
            data.zoom = map.getZoom();
          }
          // console.log("here", data);
          if (typeof callback !== "undefined") {
            callback(data);
          }
        })
        .catch((e: any) => {
          console.log(e);
        });
      // };
    },
    [callback, map, baseurl]
  );

  useEffect(() => {
    const addLayer = (map: any) => {
      let handle = null;

      // const layer: any = {
      //   id: "taxpayer",
      //   //url: "https://mcmc.tuxgeo.dev/styles/v1/tuxgeo/geohasil/tiles/256/{z}/{x}/{y}?layers=taxpayer,postcode,postcode_label,district,state",
      //   // url: "https://mcmc.tuxgeo.dev/styles/v1/tuxgeo/mcmc/tiles/256/{z}/{x}/{y}?layers=tower",
      //   url: "https://maxisnetwork.tuxgeo.dev/gwc/service/gmaps?SRS=EPSG:900913&layers=maxis:coverage_5g&zoom={z}&x={x}&y={y}&format=image/png8",
      //   display: true,
      // };

      let newlayers: any[] = [];

      for (let l = 0; l < layers.length; l++) {
        const layer = layers[l];
        if (layer && layer.url) {
          handle = new google.maps.ImageMapType({
            getTileUrl: (coord: any, zoom: number) => {
              let url = layer.url;
              url = url.replace(/\{z\}/gi, zoom);
              url = url.replace(/\{x\}/gi, coord.x);
              url = url.replace(/\{y\}/gi, coord.y);
              // console.log(window.location.protocol);
              if (!/^http/.test(url)) {
                if (/http:/.test(window.location.protocol) && mapserver) {
                  url = mapserver + url;
                }
              }
              return url;
            },
            tileSize: new google.maps.Size(256, 256),
            // isPng: true,
            name: layer.id,
            opacity: layer.opacity || 1,
          });
        }
        map.overlayMapTypes.setAt(l, handle);
        newlayers.push(handle);
      }

      setMaplayers(newlayers);
    };

    if (map !== null && marker === null) {
      // console.log("here", map);
      const myLatLng = { lat: 3.1569, lng: 101.7123 };

      // MARKER
      const newmarker = new google.maps.Marker({
        position: myLatLng,
        map,
        // title: "Marker",
        title: "Drag Me!",
        draggable: true,
        icon: {
          url: "./image/maxismarker.png",
          scaledSize: new google.maps.Size(24, 32),
          anchor: new google.maps.Point(12, 32),
        },
        animation: google.maps.Animation.DROP,
      });

      // //custom glyph marker
      // const customMarkerImg = new Image();
      // customMarkerImg.src = "./image/logo.png";

      // // image load
      // customMarkerImg.onload = () => {
      //   setMarker({
      //     url: customMarkerImg.src,
      //     scaledSize: new google.maps.Size(50, 50),
      //   });
      //   // setMarker(new google.maps.Marker());
      // };

      newmarker.addListener("dragend", (e: any) => {
        // console.log(e.latLng.toJSON());
        getCoverage(e.latLng.toJSON());
      });

      // newmarker.addListener("click", (e: any) => {
      //   // console.log(e.latLng.toJSON());
      //   getCoverage(e.latLng.toJSON());
      // });

      getCoverage(myLatLng);

      setMarker(newmarker);

      addLayer(map);
    }
  }, [map, marker, layers, getCoverage, mapserver]);

  useEffect(() => {
    if (
      map !== null &&
      typeof layers !== "undefined" &&
      typeof maplayers !== "undefined"
    ) {
      for (let l = 0; l < layers.length; l++) {
        const idx = maplayers.findIndex((layer: any) => {
          return layer?.name === layers[l].id;
        });
        if (typeof maplayers[idx] !== "undefined") {
          // maplayers[idx].setMap(null);
          map.overlayMapTypes.setAt(
            idx,
            layers[l].display ? maplayers[idx] : null
          );
        }
      }
    }
  }, [layers, maplayers, map]);

  // FETCH DATA
  // const getCoverage = (location: any) => {
  //   console.log(location);
  //   fetch(
  //     "https://maxisnetwork.tuxgeo.dev/site/maxis/coverage.cgi/" +
  //       location.lat +
  //       "," +
  //       location.lng
  //   )
  //     //status coverage

  //     .then((response) => response.json())
  //     .then((data) => {
  //       // if (data?.status && data.status === "OK") {
  //       //   window.alert("Within coverage");
  //       // } else {
  //       //   window.alert("Not within coverage");
  //       // }
  //       console.log(data);
  //       if (typeof callback !== "undefined") {
  //         callback(data);
  //       }
  //     })
  //     .catch((e: any) => {
  //       console.log(e);
  //     });
  // };

  useEffect(() => {
    if (
      typeof map !== "undefined" &&
      map !== null &&
      typeof map !== "undefined" &&
      typeof location !== "undefined" &&
      typeof marker !== "undefined"
    ) {
      map.setCenter(location);
      marker.setPosition(location);
      getCoverage(location.toJSON());
    }
  }, [map, marker, location, getCoverage]);

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  //GOOGLE MAP COMPONENT (ZOOM, MARKER, ETC)
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DEFAULT,
          position: google.maps.ControlPosition.BOTTOM_LEFT,
        },
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: true,
        controlSize: 22,
        styles: MapStyle,
      }}
    >
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
