import React, { useCallback } from "react";
// import Grid from "@mui/material/Grid";

import LayerController from "./Container";
import Map from "./Map";
import { CustomizedInputBase, MyContainer } from "./Formcontrol";

import "./App.css";
// import ShowResultContainer from "./ShowResultContainer";
interface ResultProps {
  formatted_address: string;
}

function App() {
  const [coverage, setCoverage] = React.useState<any>();
  const [geocoder, setGeocoder] = React.useState<any>();
  const [map, setMap] = React.useState<any>();
  const [location, setLocation] = React.useState<any>();
  const [results, setResults] = React.useState<ResultProps[]>();
  const [layers, setLayers] = React.useState<any[]>();

  const version = process.env.REACT_APP_TILE_VERSION
    ? process.env.REACT_APP_TILE_VERSION
    : "2024021610";

  React.useEffect(() => {
    if (
      typeof layers === "undefined" &&
      typeof version !== "undefined" &&
      version !== ""
    ) {
      setLayers([
        {
          id: "2g",
          label: "2G Coverage",
          url:
            "/gwc/service/gmaps?SRS=EPSG:900913&layers=maxis:coverage_2g&zoom={z}&x={x}&y={y}&format=image/png8&v=" +
            version,
          display: true,
          color: "#40c706",
        },
        {
          id: "4g",
          label: "4G Coverage",
          url:
            "/gwc/service/gmaps?SRS=EPSG:900913&layers=maxis:coverage_4g&zoom={z}&x={x}&y={y}&format=image/png8&v=" +
            version,
          display: true,
          color: "#0000FF",
        },
        {
          id: "5g",
          label: "5G Coverage",
          url:
            "/gwc/service/gmaps?SRS=EPSG:900913&layers=maxis:coverage_5g&zoom={z}&x={x}&y={y}&format=image/png8&v=" +
            version,
          display: true,
          color: "#ed138b",
        },
      ]);
    }
  }, [layers, version]);

  const layerCallback = (layer: any) => {
    // console.log(layer);

    if (typeof layers !== "undefined" && layers.length > 0) {
      const idx = layers.findIndex((item: any) => {
        return item?.id === layer?.id;
      });

      let newlayers = JSON.parse(JSON.stringify(layers));
      if (idx >= 0 && typeof newlayers[idx] !== "undefined") {
        newlayers[idx].display = layer?.checked;
        setLayers(newlayers);
      }
    }
    // console.log("test", coverage);
  };

  const mapDataCallback = useCallback((coverage: any) => {
    setShowContainer(true);
    setCoverage(coverage);
  }, []);

  const mapCallback = (mmap: any, mgeocoder: any) => {
    if (typeof geocoder === "undefined" && mgeocoder) {
      setGeocoder(mgeocoder);
    }
    if (typeof map === "undefined" && mmap) {
      setMap(mmap);
    }
  };

  const locationCallback = (newlocation: any) => {
    setLocation(newlocation);
  };

  const resultCallback = useCallback((newresult: any) => {
    setResults(newresult);
  }, []);

  const [showContainer, setShowContainer] = React.useState(false);

  const handleClick = () => {
    setShowContainer(!showContainer);
  };

  // console.log(results && results[0]);
  const test = results && results[0];

  return (
    <div
      style={{
        border: "0px solid #ff0000",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Map
        callback={mapDataCallback}
        mapcallback={mapCallback}
        layers={layers ? layers : []}
        location={location}
      />

      <img
        className="tuxuri"
        src="/image/tuxuri.png"
        alt="TuxGeo+"
        onClick={() => {
          window.open("https://tuxgeo.com", "tuxgeo");
        }}
      />

      {layers && <LayerController layers={layers} callback={layerCallback} />}

      <CustomizedInputBase
        onInfoClick={handleClick}
        geocoder={geocoder}
        map={map}
        locationCallback={locationCallback}
        resultCallback={resultCallback}
      />
      {showContainer && coverage && (
        <MyContainer data={coverage} callback={mapDataCallback} result={test} />
      )}
      {/* {showResultContainer && results && <MyResultsContainer data={results} />} */}
      {/* <ShowResultContainer /> */}
    </div>
  );
}

export default App;
