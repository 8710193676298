import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import DensitySmallIcon from "@mui/icons-material/DensitySmall";

import SearchIcon from "@mui/icons-material/Search";
import { Fragment, useState } from "react";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import Container from "@mui/material/Container";
import ShowResultContainer from "./ShowResultContainer";
import ShowinfoContainer from "./ShowInfoContainer";
import { getParsedCommandLineOfConfigFile } from "typescript";

interface Props {
  onInfoClick: () => void;
  geocoder?: any;
  map?: any;
  locationCallback: (l: any) => void;
  resultCallback: (results: any[]) => void;
}

export function CustomizedInputBase({
  onInfoClick,
  geocoder,
  map,
  locationCallback,
  resultCallback,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [search, setSearch] = useState<any>("");
  // const [marker, setMarker] = React.useState<any>();

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const doSearch = (e?: any) => {
    if (e) {
      e.preventDefault();
    }

    const re = /\s*(\d+\.\d+)\s*,*\s*(\d+\.\d+)\s*/;
    if (geocoder && map) {
      if (re.test(search)) {
        const parts = re.exec(search);
        if (!parts) {
          return;
        }

        const [_, lat, lng] = parts;
        // const location = { lat: parseFloat(lat), lng: parseFloat(lng) };
        const location = new google.maps.LatLng(
          parseFloat(lat),
          parseFloat(lng)
        );

        map.setCenter(location);

        if (locationCallback) {
          locationCallback(location);
        }
      } else {
        geocoder.geocode({ address: search }, (results: any, status: any) => {
          if (status === "OK") {
            // console.log("testing1", results);

            if (resultCallback) {
              resultCallback(results);
            }

            map.setCenter(results[0].geometry.location);
            if (locationCallback) {
              locationCallback(results[0].geometry.location);
            }
            // if (marker) {
            //   marker.setPosition(results[0].geometry.location);
            // }
            // if (typeof marker === "undefined") {
            //   const newmarker = new google.maps.Marker({
            //     map: map,
            //     position: results[0].geometry.location,
            //   });
            //   setMarker(newmarker);
            // } else {
            //   marker.setPosition(results[0].geometry.location);
            // }
          } else {
            alert(
              "Geocode was not successful for the following reason: " + status
            );
            if (resultCallback) {
              resultCallback([]);
            }
          }
        });
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //form control
  return (
    // <div >
    <Fragment>
      <Paper
        component="form"
        className="customizedinput"
        onSubmit={(e: any) => doSearch(e)}
      >
        <Button
          color="inherit"
          sx={{
            height: "100%",
            p: "px",
            borderRadius: "0",
            borderRight: "1px solid #B8B8B8",
            width: "35px",
            minWidth: "unset",
          }}
          aria-label="menu"
          // onClick={handleClick}
          onClick={() => {
            onInfoClick();
            // setAnchorEl(null);
          }}
        >
          <DensitySmallIcon sx={{ fontSize: "small" }} />
        </Button>
        {/* drop down menu */}
        {/* <Divider sx={{ height: "100%", m: 0.5 }} orientation="vertical" /> */}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ maxHeight: "100%", maxWidth: "100%", top: "5px" }}
        >
          <MenuItem
            onClick={() => {
              onInfoClick();
              setAnchorEl(null);
            }}
            sx={{ fontSize: 12, py: 0 }}
          >
            <InfoOutlinedIcon sx={{ mr: 1, fontSize: 15 }} />
            Info
          </MenuItem>

          <MenuItem onClick={handleClose} sx={{ fontSize: 12, py: 0 }}>
            <SearchSharpIcon sx={{ mr: 1, fontSize: 15 }} />
            Search Result
          </MenuItem>
          <Divider sx={{ height: "100%", m: 0.5 }} orientation="horizontal" />
          <MenuItem onClick={handleClose} sx={{ fontSize: 12, py: 0 }}>
            <CheckBoxOutlineBlankSharpIcon sx={{ mr: 1, fontSize: 15 }} />
            Mouse Pointer
          </MenuItem>
          <MenuItem onClick={handleClose} sx={{ fontSize: 12, py: 0 }}>
            <CachedIcon sx={{ mr: 1, fontSize: 15 }} />
            Reload
          </MenuItem>
          <MenuItem onClick={handleClose} sx={{ fontSize: 12, py: 0 }}>
            <LanguageIcon sx={{ mr: 1, fontSize: 15 }} />
            Language
          </MenuItem>

          <ul>
            <li style={{ fontSize: 12 }}>English</li>
            <li style={{ fontSize: 12 }}>Malay</li>
          </ul>
        </Menu>
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
            fontSize: 14,
          }}
          placeholder="Enter your address here"
          inputProps={{ "aria-label": "MAXIS: LTE" }}
          value={search}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
          }}
        />

        {/* <Divider sx={{ height: "100%", m: 0.5 }} orientation="vertical" /> */}
        {/* <Tooltip title="Bookmark">
            <Button
              color="inherit"
              sx={{
                p: "px",
                height: "100%",
                borderRadius: "0",
                borderLeft: "1px solid #B8B8B8",
                width: "30px",
                minWidth: "unset",
              }}
              aria-label="bookmark"
            >
              <BookmarkSharpIcon sx={{ fontSize: "large" }} />
            </Button>
          </Tooltip> */}
        {/* <Divide8
          sx={{ height: "100%", m: "0.4", colorbg: "green" }}
          orientation="vertical"
        /> */}
        <Button
          id="searchbox"
          type="button"
          sx={{
            p: "px",
            height: "100%",
            borderRadius: "0",
            borderLeft: "1px solid #B8B8B8",
            backgroundColor: "#40C706",
            ":hover": {
              backgroundColor: "#40C706",
            },
          }}
          aria-label="SearchBox"
          onClick={() => doSearch()}
        >
          <Tooltip title="Search">
            <SearchIcon
              sx={{
                fontSize: "large",
                color: "#FFFFFF",
              }}
            />
          </Tooltip>
        </Button>
      </Paper>
    </Fragment>
    // </div>
  );
}

// export function MyContainer({ data, result }: MyContainerProps) {
export function MyContainer({ data, result }: any) {
  // const [isExpanded, setIsExpanded] = useState(false);
  // const [isVisible, setIsVisible] = useState(true);

  // const handleCloseBox = () => {
  //   setIsVisible(false);
  // };

  // const handleToggleExpand = () => {
  //   setIsExpanded(!isExpanded);
  //   // setIsVisible(!isVisible);
  // };
  // if (data?.status) {
  //   dataStatus = "within coverage";
  // } else {
  //   dataStatus = "not coverage";
  // }

  // console.log("data", data);
  // console.log("test", data?.coverage_type["2G"].name);
  //
  // console.log("data", data);

  // const goToGMap = (data: any) => {
  //   // console.log(data);
  //   window.open(
  //     "https://www.google.com.my/maps/dir/" +
  //       data?.latlng +
  //       "/@" +
  //       data?.latlng +
  //       "," +
  //       data?.zoom +
  //       "z"
  //   );
  // };

  // const getCoverageType = (coveragetype: any) => {
  //   let newct: any[] = [];
  //   for (let n in coveragetype) {
  //     newct.push(n);
  //   }
  //   newct.sort();
  //   return <>{newct.join(" & ")}</>;
  // };

  // console.log(data);
  return (
    // <React.Fragment>
    //   <CssBaseline />
    <Container
      sx={{
        position: "absolute",
        height: "30px", //tukar sini
        width: "30px", //tukar sini
        marginLeft: "2px",
        top: "52px",
        // left: "5px",
      }}
    >
      {data && <ShowinfoContainer data={data} />}

      {result && <ShowResultContainer result={result} />}
    </Container>
    // </React.Fragment>
  );
}
