import { useEffect } from "react";

import { useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";

import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/HighlightOff";
import Typography from "@mui/material/Typography";

function MyContainer({ data }: any) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [networkUrl, setNetworkUrl] = useState("");

  const handleCloseBox = () => {
    setIsVisible(false);
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (data) {
      setIsExpanded(true);
    }
  }, [data]);

  useEffect(() => {
    if (data?.coverage_type) {
      const coverageType = Object.keys(data.coverage_type).sort();
      const coverageUrl = `https://store.maxis.com.my/planlist?type=${
        coverageType[coverageType.length - 1]
      }`;
      setNetworkUrl(coverageUrl);
    }
  }, [data?.coverage_type]);

  const goToGMap = (data: any) => {
    // console.log(data);
    window.open(
      "https://www.google.com.my/maps/dir/" +
        data?.latlng +
        "/@" +
        data?.latlng +
        "," +
        data?.zoom +
        "z"
    );
  };

  const getCoverageType = (coveragetype: any) => {
    let newct: any[] = [];
    for (let n in coveragetype) {
      newct.push(n);
    }
    newct.sort();
    return <>{newct.join(" & ")}</>;
  };

  return (
    <Box
      className="mycontainer"
      sx={{
        height: isExpanded ? "unset" : "33px",
        display: isVisible ? "block" : "none",
      }}
    >
      <div
        style={{
          fontSize: "75%",
          width: "100%",
        }}
      >
        <Grid container>
          <Grid item sm={8} md={8} lg={8}>
            {isExpanded ? (
              <InfoOutlinedIcon
                sx={{
                  fontSize: "large",
                  borderRadius: "5px",
                }}
              />
            ) : (
              <Typography variant="caption">Info</Typography>
            )}
          </Grid>
          <Grid
            item
            sm={4}
            md={4}
            lg={4}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {isExpanded ? (
              <ExpandLessOutlinedIcon
                sx={{
                  fontSize: "large",
                  cursor: "pointer",
                  // border: "1px solid black",
                  borderRadius: "3px",
                }}
                onClick={handleToggleExpand}
              />
            ) : (
              <ExpandMoreOutlinedIcon
                sx={{
                  fontSize: "large",
                  cursor: "pointer",
                  // border: "1px solid black",
                  marginLeft: "4px",
                  borderRadius: "3px",
                }}
                onClick={handleToggleExpand}
              />
            )}
            <CloseIcon
              sx={{
                fontSize: "large",
                cursor: "pointer",
                // border: "1px solid black",
                marginLeft: "4px",
                borderRadius: "3px",
              }}
              onClick={handleCloseBox}
            />
          </Grid>
        </Grid>

        <div style={{ display: isExpanded ? "block" : "none" }}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            padding={1}
          >
            <Grid item xs={4}>
              <img src="./image/maxis.png" alt="maxis logo" width={"100%"} />
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle1">Coverage Status</Typography>
            </Grid>
          </Grid>

          <table className="infotable">
            <tbody>
              <tr>
                <th style={{ width: "33%" }}>Service Status</th>
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "center",
                    }}
                  >
                    {data?.status ? (
                      <>
                        <CheckIcon sx={{ fontSize: 18 }} color="success" />
                        <span style={{ marginLeft: 2 }}>Within Coverage</span>
                      </>
                    ) : (
                      <>
                        <ClearIcon sx={{ fontSize: 16 }} color="success" />
                        <span style={{ marginLeft: 2 }}>
                          Not Within Coverage
                        </span>
                      </>
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <th>Location</th>
                <td
                  onClick={() => goToGMap(data)}
                  style={{ cursor: "pointer" }}
                >
                  {data?.latlng}
                </td>
              </tr>
              <tr>
                {data?.coverage_type && (
                  <>
                    <th>Coverage</th>
                    <td>{getCoverageType(data?.coverage_type)}</td>
                  </>
                )}
              </tr>
              <tr className="geocode-row">
                <th>Geocode</th>
                <td>{data?.geocode}</td>
              </tr>
            </tbody>
          </table>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="row"
          >
            <Grid item sm={12} style={{ textAlign: "center" }}>
              {data?.coverage_type && (
                <a
                  href={networkUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="morebutton"
                >
                  Learn more about the Maxis Network
                </a>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </Box>
  );
}
export default MyContainer;
